export const PREMIUM_STRUCTURE = 'premiumStructure'
export const COVER_STYLE = 'coverStyle'
export const DEFERMENT_PERIOD = 'defermentPeriod'
export const READONLY = 'READONLY'
export const MAX_RANGE = 100
export const MIN_RANGE = 0
export const PARTY_NO = 'PARTY_NO'
export const REF_NO = 'REF_NO'
export const STATUS_ACTIVE = 'ACTIVE'
export const RENEWAL_REMUNERATION_LEVEL_VALUE = 'level'
export const RENEWAL_REMUNERATION_LEVEL_CODE = 'level'
export const RENEWAL_REMUNERATION_INPUT_CODE = 'renewalRemuneration'
export const INITIAL_REMUNERATION_INPUT_CODE = 'initialRemuneration'
export const POLICY_DETAILS_PATH = 'policy-details'
export const TPD_DEFINITION = 'tpdDefinition'
export const TPD_DEFINITION_OWN = 'Own'
export const BUSINESS_REGISTRATION_TYPE = 'ASIC'
export const PARTY_TYPE_PERSON = 'PER'
export const ERROR_GET_ADV_DETAILS = 'Your request could not be submitted pls try again'
export const TOAST_ID_GET_ADV_DETAILS = 'getAdviserDetails'
export const ERROR_DELETE_ADVISER = 'Your request could not be submitted pls try again'
export const TOAST_ID_DELETE_ADVISER = 'cpDeleteAdviser'
export const SUCCESS_CP_DELETE_ADVISER = 'Your request is being serviced'
export const TOAST_ID_SUCCESS_DELETE_ADVISER = 'cpDeleteAdviserSuccess'

export const INACTIVE_ADVISER_URL = '/inactive-adviser'
export const NEW_STEPPED_LABEL = 'Variable age-stepped'

export const ADVISER_NO_KEY = 'adviserNo'
export const STAFF_STATUS_BUCKET = {
  ACT: 'Active',
}
