import moment from 'moment'

// utils
// @ts-expect-error file not in typescript
import { getBenefeciaryName } from './policyUtils'

// types
import { Relationship } from '../types/ClientPolicies'
import { Rules } from '../types/alterations'

// constants
import { POLICY_RELATIONSHIPS_LIFEASSURED } from '../constants/policies'

// Picking variant based on the deadline
export const pickVariantByRange = (percentage: number) => {
  if (percentage >= 1 && percentage <= 5) return 'danger'
  if (percentage > 6 && percentage <= 20) return 'warning'
  return 'default'
}

// Life Insured names from relationships, sorting with primary insured on top
// Appending primary in case of multiple life assured
export const getAllLifeInsuredName = (relationships: Relationship[]) => {
  const allLifeInsured = relationships
    .filter(({ roleCode }) => roleCode === POLICY_RELATIONSHIPS_LIFEASSURED)
    .sort(
      (a, b) =>
        (a.isPrimaryLifeAssured === 'Y' ? -1 : 1) - (b.isPrimaryLifeAssured === 'Y' ? -1 : 1)
    )
  const lifeInsuredNames = allLifeInsured
    .map(({ relatedParty, isPrimaryLifeAssured }) =>
      allLifeInsured.length > 1 && isPrimaryLifeAssured === 'Y'
        ? `${getBenefeciaryName(relatedParty) as string} (Primary)`
        : (getBenefeciaryName(relatedParty) as string)
    )
    .join(', ')
  return lifeInsuredNames
}

// Calculating esclatation deadline for Reject CPI
export const getEscalationDeadlineDaysForPolicy = (rules: Rules, bancsPolicyNo: string) => {
  const policiesListInRules = rules?.businessData?.policies || []
  const matchingPolicyinAlterationRules = policiesListInRules.find(
    policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
  )
  const escalationRejectionDeadline =
    matchingPolicyinAlterationRules?.assesment?.rejectCPI_EscalationRejectionDeadline
  const formattedEscalationRejectionDeadline = escalationRejectionDeadline
    ? moment(escalationRejectionDeadline, 'DD/MM/YYYY')
    : ''
  const today = moment(rules?.businessData?.assesment.systemInfo_TodaysDate, 'DD/MM/YYYY')
  const escalationDeadLine = formattedEscalationRejectionDeadline
    ? formattedEscalationRejectionDeadline.diff(today, 'days') + 1
    : 0
  return escalationDeadLine
}
