import { createSelector } from 'reselect'
import moment from 'moment'

// selectors
import { getRulesForAlterationPolicies } from './altsDeclaration.selectors'
import { getAlterationPolicies, getIsAdviserPortal, getMasterData } from './common.selectors'

// constants
import { ALTERATION_TYPES } from '../constants/alterations'

// types
import { PolicyEligiblityKey } from '../types/alterations'

// utils
// @ts-expect-error file not in typescript
import { getPolicyTypeLabel } from '../utils/policyUtils'
import {
  pickVariantByRange,
  getAllLifeInsuredName,
  getEscalationDeadlineDaysForPolicy,
} from '../utils/alterationPolicies'
// @ts-expect-error file not in typescript
import { policyInvalidMessageForUplift } from '../utils/alterationRules'

export const getAlterationPoliciesByEligibilty = (alterationType: string, isEligible: boolean) =>
  createSelector(
    [getAlterationPolicies, getRulesForAlterationPolicies, getIsAdviserPortal],
    (alterationPolicies, rules, isAdviserPortal) => {
      const policiesListInRules = rules?.businessData?.policies || []
      return alterationPolicies.filter(({ bancsPolicyNo }: { bancsPolicyNo: string }) => {
        const matchingPolicyinAlterationRules = policiesListInRules.find(
          policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
        )
        let policyEligibilityKey: PolicyEligiblityKey
        switch (alterationType) {
          case ALTERATION_TYPES.DECREASE_RISK: {
            policyEligibilityKey = isAdviserPortal
              ? 'decrease_PolicyEligibleForAdviser'
              : 'decrease_PolicyEligibleForCustomer'
            break
          }
          default: {
            policyEligibilityKey = isAdviserPortal
              ? 'rejectCPI_PolicyEligibleForAdviser'
              : 'rejectCPI_PolicyEligibleForCustomer'
          }
        }
        return (
          matchingPolicyinAlterationRules?.assesment?.[policyEligibilityKey] ===
          (isEligible ? 'Y' : 'N')
        )
      })
    }
  )

export const getAlterationPoliciesData = (alterationType: string, isEligible = true) =>
  createSelector(
    [
      getAlterationPoliciesByEligibilty(alterationType, isEligible),
      getRulesForAlterationPolicies,
      getMasterData,
    ],
    (policies, rules, masterData) =>
      policies.map(({ policy, bancsPolicyNo }) => {
        const policiesListInRules = rules?.businessData?.policies || []
        const matchingPolicyinAlterationRules = policiesListInRules.find(
          policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
        )
        const escalationDeadLine = getEscalationDeadlineDaysForPolicy(rules, bancsPolicyNo)
        return {
          policyNumber: policy.identifiers?.find(item => item.type === 'POLICY_ID')?.value,
          productType: getPolicyTypeLabel(policy.productClass),
          anniversary: moment(
            matchingPolicyinAlterationRules?.assesment.rejectCPI_EscalationDate,
            'DD/MM/YYYY'
          ).format('DD MMM YYYY'),
          lifeInsured: getAllLifeInsuredName(policy.relationships),
          ...(isEligible && {
            daysRemaining: {
              variant: pickVariantByRange(escalationDeadLine),
              percentage: Math.ceil((escalationDeadLine / 105) * 100),
              label: escalationDeadLine,
            },
          }),
          ...(!isEligible && {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            reasonOptOut: policyInvalidMessageForUplift(
              rules?.businessData?.policies ?? [],
              bancsPolicyNo,
              (masterData?.alterationsModalMessages ?? []).concat(
                masterData?.rejectCPICardMessages ?? []
              )
            ),
          }),
        }
      })
  )
