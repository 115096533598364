// v2 is initially created for Inflation proofing uplift in CP
// v2 might also be used for the uplift in AP in future
import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Heading, Button, Tooltip, Chip } from '@mlcl-digital/mlcl-design'
import Table from '@mlcl-digital/mlcl-design/lib/base/Table'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import ProgressBar from '@mlcl-digital/mlcl-design/lib/base/ProgressBar'

// types
import { Fields, Props } from '../../../../types/components/AltsLandingPage'

// selectors
// @ts-expect-error File not in typescript
import { getAlterationType } from '../../../../selectors/alterations'

// utils
import { createEvent } from '../../../../utils/telemetry'
// @ts-expect-error File not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'
// @ts-expect-error File not in typescript
import history from '../../../../utils/browserHistory'

// styles
import styles from './styles'

// actions
// @ts-expect-error File not in typescript
import { setAlterationTypeForAlterationsRoute } from '../../../../actions/creators/alterations'
// @ts-expect-error File not in typescript
import { createAltsQuote } from '../../../../actions/creators/createQuote'
import { getAlterationPoliciesData } from '../../../../selectors/alterationPolicies'

const PageContainer = styled('div')(styles.pageContainer)
const Description = styled('p')(styles.description)
const InflationProofingDetails = styled('div')(styles.inflationProofingDetails)
const TooltipContainer = styled('div')(styles.tooltipContainer)

const columns = (
  {
    PolicyNumber,
    Anniversary,
    LifeInsured,
    DaysRemaining,
    ReasonOptOut,
    DaysRemainingTooltip,
  }: Fields,
  isEligible: boolean
) => [
  {
    Header: PolicyNumber?.value,
    accessor: 'policyNumber',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'productType',
    disableSortBy: true,
    Cell: ({ value }: { value: string }) => (
      <Chip variant={value === 'Inside Super' ? 'default' : 'important'}>{value}</Chip>
    ),
  },
  {
    Header: Anniversary?.value,
    accessor: 'anniversary',
    disableSortBy: true,
  },
  {
    Header: LifeInsured?.value,
    accessor: 'lifeInsured',
    disableSortBy: true,
  },
  ...(isEligible
    ? [
        {
          Header: (
            <div>
              <span>{DaysRemaining?.value}</span>
              <TooltipContainer>
                <span data-tip data-for="days-remaining">
                  <Icon iconName={['far', 'circle-info']} />
                </span>
                <Tooltip id="days-remaining">{renderTextField(DaysRemainingTooltip, true)}</Tooltip>
              </TooltipContainer>
            </div>
          ),
          accessor: 'daysRemaining',
          disableSortBy: true,
          Cell: ({
            value: daysRemaining,
          }: {
            value: { variant: 'danger' | 'default' | 'warning'; percentage: number; label: number }
          }) => <ProgressBar {...daysRemaining} />,
        },
      ]
    : []),
  ...(!isEligible
    ? [
        {
          Header: ReasonOptOut?.value,
          accessor: 'reasonOptOut',
          disableSortBy: true,
        },
      ]
    : []),
]

const AltsLandingPage = (props: Props) => {
  const dispatch = useDispatch()
  const alterationType: string = useSelector(getAlterationType())
  const eligiblePolicies = useSelector(getAlterationPoliciesData(alterationType, true))
  const inEligiblePolicies = useSelector(getAlterationPoliciesData(alterationType, false))

  const { fields, params } = props

  const COLUMNS_ELIGIBLE_POLICIES = columns(fields, true)
  const COLUMNS_INELIGIBLE_POLICIES = columns(fields, false)

  useEffect(() => {
    dispatch(
      createAltsQuote({
        alterationType: params.AlterationType,
      })
    )

    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: `${alterationType} landing page`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - landing page`,
        },
      },
    })
    tagEvent.end()
  }, [])

  const handleClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(setAlterationTypeForAlterationsRoute(params.AlterationType))

    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: `Enter ${alterationType} alteration`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Enter ${alterationType} alteration`,
        },
      },
    })
    tagEvent.end()

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.replace(fields.StartApplicationButton?.value?.href)
  }, [])

  return (
    <PageContainer>
      <InflationProofingDetails>
        <Heading variant="h2" size="LG">
          {renderTextField(fields.Heading)}
        </Heading>
        <Description>{renderTextField(fields.Description, true)}</Description>
        <Button variant="primary" onClick={handleClick}>
          {fields.StartApplicationButton?.value?.text}
        </Button>
      </InflationProofingDetails>
      {eligiblePolicies.length > 0 && (
        <>
          <Heading variant="h3" size="LG">
            {renderTextField(fields.PoliciesEligibleHeading)}
          </Heading>
          <Table
            pageSize={20}
            data={eligiblePolicies}
            columns={COLUMNS_ELIGIBLE_POLICIES}
            styleOverrides={styles.styleOverrides}
          />
        </>
      )}
      {inEligiblePolicies.length > 0 && (
        <>
          <Heading variant="h3" size="LG">
            {renderTextField(fields.PoliciesIneligibleHeading)}
          </Heading>
          <Table
            pageSize={20}
            data={inEligiblePolicies}
            styleOverrides={styles.styleOverrides}
            columns={COLUMNS_INELIGIBLE_POLICIES}
          />
        </>
      )}
    </PageContainer>
  )
}

export default AltsLandingPage
