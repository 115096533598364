import { Variables } from '@mlcl-digital/mlcl-design'
import { colours, space, fontSize, fontFamily, shadows } from '../../../../../styles'

const { colours: libColours, space: libSpace } = Variables
const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: space(1, true),
    paddingRight: space(1, true),
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colours.lightestGrey,
    borderStyle: 'dashed',
    backgroundColor: colours.white,
    transition: 'border .25s ease-in-out',
    boxShadow: shadows.low,
    marginBottom: space(2, true),
  },

  descriptionBox: {
    color: colours.darkestGrey,
  },

  fileInput: {
    width: 0,
    height: 0,
    overflow: 'hidden',
  },

  browseLink: {
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontSize.semiBold,
    fontSize: fontSize.xs,
    lineHeight: fontSize.md,
    padding: space(0.5, true),
  },

  fileUploadIcon: isFileUploading => ({
    marginTop: space(2, true),
    color: isFileUploading ? colours.mediumGrey : colours.mediumGreen,
  }),
  infoIcon: {
    color: libColours.mlcAquaAccessible,
    verticalAlign: '-webkit-baseline-middle',
  },
  iconWrapper: {
    width: libSpace(5),
    height: libSpace(5),
    border: `1px solid ${libColours.lightestGrey}`,
    backgroundColor: libColours.tertiaryFive,
    borderRadius: '100%',
    textAlign: 'center',
  },
}

export default styles
